<template>
  <div class="h-36 bg-indigo-900 relative -top-12"></div>
  <LSection>
    <template #body>
      <div
        class="qtEduResource relative -top-36 bg-slate-50 p-6 k1:p-12 shadow-2xl"
      >
        <div
          class="bg-gradient-to-b from-[#ff7a07] absolute top-4 -right-4 to-[#ff0576] h-20 w-4"
        ></div>

        <div class="flex gap-4 items-center pb-8" v-if="program.isInternship">
          <div class="w-7 h-10 intern"></div>
          INTERNSHIP OPPORTUNITY
        </div>

        <div class="Level">
          <div class="flex flex-col k1:flex-row gap-2 k1:gap-12 pb-4">
            <span
              class="flex gap-3 items-center font-semibold text-indigo-950 font-normal text-sm capitalize"
              v-for="level in program.level"
              :key="level"
            >
              <img :src="levelImages[level]" alt="" class="w-5 h-6" />
              {{ level }}
            </span>
          </div>
        </div>

        <h1 class="heading1 !font-normal !text-black">{{ title }}</h1>
        <div class="grid w-full grid-cols-3 pb-8">
          <div class="flex-col DataType p-2">
            <div class="text-gray-400 text-md pb-2 uppercase">Type</div>
            <div
              v-for="type in program.type"
              :key="type"
              class="text-indigo-950 font-semibold text-sm uppercase"
            >
              {{ type }}
            </div>
          </div>

          <div
            v-if="program.lang.length"
            class="flex-col p-2 border-black border-1 border-l"
          >
            <p class="flex text-gray-400 text-md uppercase pb-2">Languages</p>
            <div class="flex flex-wrap gap-3">
              <div
                class="font-semibold w-fit text-sm capitalize"
                v-for="language in program.lang"
                :key="language"
              >
                {{ language }}
              </div>
            </div>
          </div>
          <!--          <div class="p-2 border-black border-1 border-l">
            <p class="text-gray-400 text-md uppercase pb-2">Contacts</p>
            <div class="flex justify-start gap-1 flex-col">
              <div
                class="text-sm"
                v-for="contact in program.contacts"
                :key="contact"
              >
                <p class="font-semibold">
                  {{ contact.first }}
                </p>
                <a :href="'mailto:' + contact.second">
                  {{ contact.second }}
                </a>
              </div>
            </div>
          </div>
          contact info, commented out until we have green light to include it-->
        </div>

        <div class="flex flex-col content gap-4">
          <p v-html="program.summary" class="italic"></p>
          <p v-html="program.description"></p>
        </div>
        <div
          class="flex flex-col gap-6 right-Col lg:w-[25%] border-t-2 pt-4 mt-2 lg:border-l-2 lg:border-t-0 lg:pl-4 border-dashed border-gray-300 space-y-2"
        >
          <div class="Org" v-if="program.org.length">
            <div class="text-gray-400 text-md pb-1 uppercase">Provided By</div>
            <div class="provided">
              <p
                class="font-semibold text-sm capitalize"
                v-for="provider in program.org"
                :key="provider"
              >
                {{ provider }}
              </p>
            </div>
            <div
              class="flex whitespace-nowrap text-sm capitalize"
              v-for="(country, index) in program.country"
              :key="index"
            >
              <p v-if="program.city[index]" class="pr-1">
                {{ program.city[index] }},
              </p>
              <p>{{ country }}</p>
            </div>
          </div>
          <div class="Target" v-if="program.target.length">
            <div class="text-gray-400 text-md pb-1 uppercase">Targest</div>
            <div class="flex justify-start gap-x-6 gap-y-2 flex-wrap">
              <div
                class="flex gap-2 items-center"
                v-for="target in program.target"
                :key="target"
              >
                <img
                  :src="'https://qtedu.eu/img/target.9c56fd60.svg'"
                  alt=""
                  class="w-5 h-6"
                />
                <p class="font-semibold text-sm capitalize">
                  {{ target }}
                </p>
              </div>
            </div>
          </div>
          <div class="Topic">
            <div class="text-gray-400 text-md pb-1 uppercase">Topics</div>
            <div class="topic">
              <div class="flex flex-row justify-start gap-2 flex-wrap">
                <div
                  class="font-semibold text-sm capitalize p-2 px-4 rounded-full border-slate-400 border-2 w-fit"
                  v-for="topic in program.topic"
                  :key="topic"
                >
                  {{ topic }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import { useQTEduResources } from "@/composables/qtEdu/useQTEduResources";
import LSection from "@/components/layout/LSection.vue";
import { ref } from "vue";

export default {
  name: "QTEduResource",
  components: { LSection },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { program } = useQTEduResources(props.title);

    const levelImages = ref({
      "Higher education":
        "https://qtedu.eu/img/icon_higher_small_shade.43908eaa.svg",
      "Lifelong learning and (re)training":
        "https://qtedu.eu/img/icon_lifelong_small_shade.7c8941fe.svg",
      Outreach:
        "https://qtedu.eu/img/icon_transversal_small_shade.88f7e719.svg",
      "Primary and Secondary School":
        "https://qtedu.eu/img/icon_primary_small_shade.321d6e39.svg",
    });

    return {
      program,
      levelImages,
    };
  },
};
</script>

<style scoped></style>
